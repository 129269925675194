import React, { useContext, useState } from "react";
import { Typography, Box, Button, Grid, TextField, Link, InputLabel, OutlinedInput, IconButton, InputAdornment, FormControl, Modal } from "@mui/material";
import PasswordBox from "./PasswordBox";
import img from './crest.png'
import { NavLink, useNavigate } from "react-router-dom";
import { Email, Visibility } from "@mui/icons-material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "axios";
// import { UserContext } from "ContextFiles";

import { isAuthContext } from "routes";
import ForgotPassword from "./ForgotPasswordEmail";

const style = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4

};

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState()
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState("");
  const contextUser = useContext(isAuthContext)
  const [showPassword, setShowPassword] = React.useState(false);
  

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClick = () => {
    alert("path not set")
  }

  

  const onSubmit = () => {
    console.log(email)
    axios({
      url:"https://cms.keewesolutions.com/university/getdata",
      method:"post",
      data:{email:email}
    }).then((response)=>{
      
      
    if(response.data.Item.email=="info@mrsptuonline.com" && password==response.data.Item.password){
      contextUser.setIsAuth(true)
      localStorage.setItem("LoginState",true)
      navigate('/')
      window.location.reload()
    }
    else if(response.data.Item.email == "karanvir@mrsptu.ac.in" && password ==response.data.Item.password){
      contextUser.setIsAuth(true)
      localStorage.setItem("coelogin",true)
      navigate('/')
      window.location.reload()
    }
    else if(response.data.Item.email == "onlinemrsptuaccounts@mrsptu.ac.in" && password ==response.data.Item.password){
      contextUser.setIsAuth(true)
      localStorage.setItem("finance",true)
      navigate('/')
      window.location.reload()
    }
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (

    <Grid container lg={12} xs={12} sm={12} md={12} sx={{ width: "auto" }}>
      <form >
        <Grid item lg={12} xs={12} sm={12} md={12} sx={{ marginBottom: "20px" }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Public Username or Email</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              label="Public Username or Email"
              onChange={event => setEmail(event.target.value)}
              value={email}

            />
          </FormControl>
        </Grid>

        {/* <Grid xs={4}/> */}
        <Grid item lg={12} xs={12} sm={112} md={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              label="password"
              onChange={event => setPassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              value={password}

              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={()=>{setShowPassword(true)}}
                    // onMouseDown={handleMouseDownPassword}
                    onClick={togglePassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }

            />
          </FormControl>
        </Grid>
        {/* <Grid xs={4}/> */}
        <Grid item lg={12} xs={12} sm={12} md={12} sx={{ marginTop: "30px", marginBottom: "20px" }}>
          <Box display="flex" >
            <Grid container alignItems={'center'}>
              <Button
                size="medium"
                onClick={()=>{onSubmit()}}
                // type="submit"
                variant="contained"
                sx={{ backgroundColor: "#06213F", borderRadius: '4px', textTransform: 'none', marginRight: '5%' }} >Sign In</Button>
              <Typography onClick={handleOpen} fullWidth sx={{ "&:hover": { textDecoration: 'underline', cursor: 'pointer' }, color: "#06213F", textTransform: 'none' }}>Forgot Password ?</Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} />
        <br />
        <br />
        

        <Grid xs={12} />

       
      </form>

      <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{overflowY:"scroll"}}
                        >
                           
                                <ForgotPassword handleClose1={handleClose}/>
                            
                        </Modal>

    </Grid>

  )
}