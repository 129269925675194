import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import utilities1 from './utilitiesCOE';
import utilities2 from './utilitiesfinance';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

let menuItems
    if (localStorage.getItem("LoginState")) {
         menuItems = {
        items: [utilities]
        }
    } else if(localStorage.getItem("coelogin")){
         menuItems = {
            items: [utilities1]
            }   
         } 
    else  if(localStorage.getItem("finance")) {
                menuItems = {
                    items: [utilities2]
                    }             
                }
    else {
        menuItems={
            items:[]
        }
    }
   
;

export default menuItems;
