// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Home',
            type: 'item',
            url: '/home',
            // icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'tabler-icons',
            title: 'All Payments',
            type: 'item',
            url: '/allpayment',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons34',
            title: 'Pending',
            type: 'item',
            url: '/payment',
            breadcrumbs: false
        },  
        {
            id: 'tabler-icons1',
            title: 'Approved Payments',
            type: 'item',
            url: '/approved',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons2',
            title: 'Disapproved Payments',
            type: 'item',
            url: '/disapproved',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons3',
            title: 'Invoices',
            type: 'item',
            url: '/invoices',
            breadcrumbs: false
        }
    ]
};

export default utilities;
