import React,{useState} from "react";
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid,Paper,  TextField, Button, Typography,Link, Modal } from '@mui/material';
import NewPassword from "./NewPassword";
// import VectorNew from "../components/VectorNew.png";
const ForgotPassword=({handleClose1}) =>  {

    const paperStyle={padding :20,height:'80vh',width:"max-content", margin:"20px auto"}

    
    const navigate = useNavigate();
    const [email,setEmail]=useState("");
    const [otp,setotp] = useState("")
    const [open, setOpen] = useState(false)
    const location = useLocation();
//    if(location.state!=null){
//     console.log(location.state.user)
//    }
   const handleOpen=()=>{
    setOpen(true)
   }

   const handleClose=()=>{
    setOpen(false)
   }
   
   const onSubmit=event=>{
    event.preventDefault();
    
    const otp = Math.floor(1000 + Math. random() * 9000);
    
    axios({
        url:'https://cms.keewesolutions.com/mailer/sendOtp',
        method:'POST',
        data:{
        email:email,
        otp:otp
        }
    })
    .then(
        (response) => {
            // console.log(response.data)
            // navigate('/changepassword',{state:{user:email,otp:otp}});
           
            setotp(otp)

            handleOpen()
           
        }
    );
    }
    return(
        <Grid>
        <Paper elevation={10} style={paperStyle}>
            <form onSubmit={onSubmit}>
            <Grid align='center'>
               
                
                <div align="center">
                {/* <img src={VectorNew} alt="iamage" style={{width: "-webkit-fill-available"}}/> */}
                </div>
                <h2>Forgot Password</h2>
                <h6>Verification Code will be sent on Email</h6>
            </Grid>
            <TextField 
            value={email}
            onChange={event=>setEmail(event.target.value)}
            variant="outlined" label='Enter Email' fullWidth required/>
            <Button type='submit'  variant="contained" style={{backgroundColor:"#06213F", color:"white", height:"56px",fontSize:"20px", marginTop:"10px", marginBottom:"10px"}} fullWidth>SEND CODE</Button>
            </form>
        </Paper>
         <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         style={{overflowY:"scroll"}}
     >
        
             <NewPassword otp={otp} email={email} handleClose={handleClose} handleClose1={handleClose1}/>
         
     </Modal>
     </Grid>
)
}

export default ForgotPassword
    