// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Home',
            type: 'item',
            url: '/home',
            // icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'util-typography',
            title: 'Pending',
            type: 'item',
            url: '/students',
            // icon: icons.Student,
            breadcrumbs: false
        },
        {
            id: 'util-typography2',
            title: 'Approved Students',
            type: 'item',
            url: '/approvedstudent',
            // icon: icons.Student,
            breadcrumbs: false
        },
        {
            id: 'util-typography3',
            title: 'Disapproved Students',
            type: 'item',
            url: '/rejectedstudents',
            // icon: icons.Student,
            breadcrumbs: false
        },
        {
            id: 'util-typography1',
            title: 'Generated Certificates',
            type: 'item',
            url: '/approvedcertificate',
            // icon: icons.Student,
            breadcrumbs: false
        },
    ]
};

export default utilities;
