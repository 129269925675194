import React, { useEffect, useState } from "react";

import { Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import logo from "./Mrsptu.png"
import axios from "axios";
import Pdf from "react-to-pdf";
import { Button } from '@mui/material';
const ref = React.createRef();


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const Bill = ({data,coursename,orderId,amount}) => {
    const[receipt , setreceipt] = useState([])
    const[dateofpayment , setdateofpayment] = useState([])
    
    
    useEffect(async()=>{
        await axios({
          url:"https://cms.keewesolutions.com/university/studentorder",
          method:"post",
          data:{id:orderId}
        }).then((res)=>{
           
            setreceipt(res.data.Items[0])
        })
    },[])
    const options = {
        orientation: 'potrait',
        unit: 'in',
        format: [45, 60]
      };
      
      let date = new Date(receipt.created_at*1000);
       const paymentDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
       
    const rows = [
        createData("Name", data),
        createData("Course Name", coursename),
        createData("Course Id ", receipt.courseId),
        createData("Order ID", orderId),
        createData("Receipt No.#", receipt.receipt),
        createData("Payment Date", paymentDate)
      ];
      
  return (
    <Grid container style={{ padding: 30 }}>
        <Pdf targetRef={ref} filename="invoice.pdf" options={options} scale='5' >
        {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
      </Pdf>
      <div ref={ref} style={{width:"max-content"}}>
        <Grid item lg={12} style={{textAlign:"center"}}>
    <h2 >Receipt</h2>
      </Grid>
      <Grid container item lg={12}>
      <Grid item lg={1} />
        <Grid item lg={5} style={{ marginTop: 32 }}>
          <img src={logo}/>
        </Grid>
    
         <Grid item lg={5} style={{ marginTop: 32 }}>
          
            <Grid>
            
             <h4> Maharaja Ranjit Singh Punjab Technical University, Bathinda</h4>
              
        
            </Grid>
            
            <Grid item style={{textAlign:"left"}}>
        
            <h3> Payment : ₹ {amount}</h3>
              
        
            </Grid>
        
         </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container item>
        <Grid item lg={1.5} />
        <Grid item lg={10} style={{ marginTop: 32 }}>
          <TableContainer>
            <Table aria-label="customized table">
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.calories}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      </div>
    </Grid>
  );
};

export default Bill;
