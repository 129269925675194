import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Card, Fade, FormControl, FormControlLabel, FormLabel, Grid, Modal, Paper, Popper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { countContext } from 'index';
import Bill from './Bill';

// ===============================|| COLOR BOX ||=============================== //

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const ColorBox = ({ bgcolor, title, data, dark }) => (
    <>
        <Card sx={{ mb: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 4.5,
                    bgcolor,
                    color: dark ? 'grey.800' : '#ffffff'
                }}
            >
                {title && (
                    <Typography variant="subtitle1" color="inherit">
                        {title}
                    </Typography>
                )}
                {!title && <Box sx={{ p: 1.15 }} />}
            </Box>
        </Card>
        {data && (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle2">{data.label}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
                        {data.color}
                    </Typography>
                </Grid>
            </Grid>
        )}
    </>
);

ColorBox.propTypes = {
    bgcolor: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object.isRequired,
    dark: PropTypes.bool
};

// ===============================|| UI COLOR ||=============================== //

const Invoices = () => {
    let context = useContext(countContext)
    const [row, setRow] = useState([])
    const [rw, setrw] = useState([])
    const [data, setdata] = useState("")
    const [coursename, setcoursename] = useState("")
    const [orderId, setorderId] = useState("")
    const [amount, setamount] = useState("")
    const [newData, setNewData] = useState([])
    const [nawaData, setNawaData] = useState([])
    const [open, setopen] = useState(false)
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [open1, setOpen1] = useState(false);
    // const [open2, setOpen2] = useState(false);
    const [statusData, setstatusData] = useState({})
    const [status, setStatus] = useState('Status')
    const[PaymentStatus, setPaymentStatus] = useState([])

    const [info, setInfo] = useState([])

    const handleStatus = (event) => {
        setAnchorEl1(event.currentTarget);
        setOpen1((previousOpen) => !previousOpen);
        // submitStatus()
      };

    const canBeOpen1 = open1 && Boolean(anchorEl1);
        const id1 = canBeOpen1 ? 'spring-popper' : undefined;

     const submitStatus = async(e)=>{
            console.log(statusData)
            console.log(nawaData)
           await axios({
            url:"https://cms.keewesolutions.com/university/paystatus",
            method:"post",
            data:{...statusData, status:e}
           }).then(res=>{
           console.log(res)
            setStatus(e)
            getStatus()
            // setOpen1(open1)
           })
        }     

    const handleView=(e,f,g,h)=>{
        setdata(e)
        setcoursename(f)
        setorderId(g)
        setamount(h)
       setopen(true)
    }
    const handleClose=()=>{
      setopen(false)
    }

    const getStatus = async ()=>{
        await axios({
            url: "https://cms.keewesolutions.com/university/getPayStatus",
            method: "POST",

        }).then((res)=>{
            
            setInfo(res.data.response)
        })
    }


    const [loading, setloading] = useState(true)
    

    useEffect(() => {

        // setTimeout(() => {
        //     setloading(false)
        // }, 2000);

        const getUserData = async () => {
            await axios({
                url: "https://cms.keewesolutions.com/user/getUserData",
                method: "POST"
            }).then(res => {
                console.log(res.data.Items)
                setNewData(res.data.Items)
                NewFunc(res.data.Items)
            })
        }
        getUserData();

        
        getStatus()
    }, [])

    const NewFunc = (e) => {
        
        let abc;
        async function getTeachers() {
            try {
                const callRes = await axios({
                    url: "https://cms.keewesolutions.com/university/paymentlist", method: 'GET'
                })
                console.log(callRes.data.response)
                abc = callRes.data.response.map((item) => {
                    return {
                        id: item.courseId,
                        userId: item.userId,
                        amount: item.amount / 100,
                        orderId: item.orderId,
                        paymentStatus: item.paymentStatus
                    }
                })

                // abc = dataRowArr
                setRow(abc)

                coursename()

            } catch (error) {
                console.log(error)
            }
        }
        getTeachers()



        var coursename = async () => {
            try {
                const callRes = await axios({
                    url: "https://cms.keewesolutions.com/university/coursename", method: 'GET'
                })
                console.log(callRes.data.response)
                const dataRowArr = callRes.data.response.map((item, index) => {
                    let originalprice;
                    return {
                        courseName: item.title,
                        courseId: item.courseId,
                        originalprice:item.orginal
                    }
                })
                console.log(dataRowArr)
                let arr = [];
                for (let i in abc) {
                    const arr0 = dataRowArr.filter(item => {
                        if (abc[i].id == item.courseId) {
                            abc[i].courseName = item.courseName
                            abc[i].originalprice = item.originalprice
                            arr.push(abc[i])
                            return abc[i]
                        }
                    })

                }
                console.log(arr)
                setrw(arr)

                let arr1 = [];
                let arr2 = arr.slice()

                for (let j in arr2){
                    let xyz = arr2[j]
                    for (let i in e){
                        if (e[i].username.toLowerCase() == arr2[j].userId.toLowerCase()){
                            xyz = {...e[i],...arr2[j]}
                            arr1.push(xyz)
                        }
                        
                    }
                }
                
                setNawaData(arr1)
                setloading(false)
            } catch (error) {
                console.log(error)
            }
        }
      

        async function paymentStatus() {
            try {
                const Payments = await axios({
                    url: "https://cms.keewesolutions.com/university/querypayment",
                    method: "post",
                    data:{status:"Approved"}
                })
                console.log(Payments.data.Items)
                setPaymentStatus(Payments.data.Items)
        
            } catch (error) {
                console.log(error)
            }
        }
        paymentStatus()

        
    }


    return (
        <MainCard title="Invoices Details" secondary={function () {
            return (
                <>
                    <span>Approved Students Invoices :{PaymentStatus.length}</span>
                </>
            )
        }()
        }
        
        >
            <Popper id={id1} open={open1} anchorEl={anchorEl1}  transition style={{zIndex:'1000000'}}> 
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
            <FormControl>
      <FormLabel id="demo-radio-buttons-group-label"> Status</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onChange={(e)=>{setStatus(e.target.value)
        submitStatus(e.target.value)
        setOpen1((e)=>!e)
    }}
        value={status}
      >
        <FormControlLabel value="Approved" control={<Radio />} label="Approve" />
        <FormControlLabel value="Rejected" control={<Radio />} label="Disapprove" />
      </RadioGroup>
    </FormControl>
            </Box>
          </Fade>
        )}
      </Popper>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">Student Name</TableCell>
                                    <TableCell align="center">Course Name</TableCell>
                                    <TableCell align="center">Invoice</TableCell>
                                    <TableCell align="center">Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading?<p>LOADING...</p>:nawaData.map((item) => {
                                //    let originalprice;
                                //    for(i in price){
                                //     if(item.coursename.toLowerCase()==)
                                //    }
                                    // {rw.map((item))}
                                    var payStatus;
                                    for(let i in info){
                                    if(item.username == info[i].userId && item.id == info[i].courseId){
                                        
                                        payStatus = info[i].paymentStatus
                                    }
                                    
}
                                    let show = false;
                                    for(let i in PaymentStatus){
                                        if(item.username == PaymentStatus[i].userId && item.id == PaymentStatus[i].courseId){
                                          show = true;
                                        }
                                    }

                                   if(show){
                                    return (
                                        <TableRow
                                            key={item.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        ><TableCell component="th" scope="row">
                                        <ul ><li></li></ul>
                                     </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="center">{item.courseName}</TableCell>
                                            <TableCell align="center"><Button onClick={()=>handleView(item.name,item.courseName,item.orderId,item.amount)}>View</Button></TableCell>
                                            <TableCell align="center">{payStatus ? payStatus:"Status"}</TableCell>
                                        </TableRow>
                                    )}
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                               <Bill  data={data} coursename={coursename} orderId={orderId} amount={amount}/>
                            </Box>
                        </Modal>
                </Grid>
                
            </Grid>
        </MainCard>
    )
};

export default Invoices;




