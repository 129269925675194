import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router';
import { useState } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




export default function ReviewList() {

    const {state} = useLocation();
    console.log(state);

    const [row, setRow]= useState([]);
    const [value, setValue] = useState("second")

    useEffect(async() => {
        const response = await axios({
            url:"https://cms.keewesolutions.com/courses/GetUserReview",
            method:"post",
           
                })
                console.log(response.data)
                setRow(response.data.Items)
      
    }, [value])
    

    const handleApprove = async(e,index)=>{
        console.log("Approve",e);
        const Data = {...e,Status:"Approved"}
        console.log(Data)

        await axios({
            url:"https://cms.keewesolutions.com/courses/AddReview",
            method:"post",
            data:Data
        }).then((res)=>{
            console.log(res)
            // window.location.reload();
            setValue("yes")
            setValue("no")

        })
    }

    const handleReject = async(e,index)=>{
        console.log("Reject",e);
        const Data = {...e,Status:"Rejected"}
        console.log(Data)

        await axios({
            url:"https://cms.keewesolutions.com/courses/AddReview",
            method:"post",
            data:Data
        }).then((res)=>{
            console.log(res)
            // window.location.reload();
            setValue("yes")
            setValue("no")
        })
    }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>User ID</StyledTableCell>
            <StyledTableCell align="left">Rating</StyledTableCell>
            <StyledTableCell align="center">Review</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">Approve</StyledTableCell>
            <StyledTableCell align="right">Reject</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {row.map((row,index) => {
            if(row.CourseId==state.CourseId){
                return(
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.UserId}
              </StyledTableCell>
              <StyledTableCell align="left">{row.Rating}</StyledTableCell>
              <StyledTableCell align="center">{row.Review}</StyledTableCell>
              <StyledTableCell align="right">{row.Status}</StyledTableCell>
              <StyledTableCell align="right"><Button onClick={()=>{handleApprove(row,index)}}>Approve</Button></StyledTableCell>
              <StyledTableCell align="right"><Button onClick={()=>{handleReject(row,index)}}>Reject</Button></StyledTableCell>

            </StyledTableRow>)}
})}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


















// import React from 'react'
// import { useLocation } from 'react-router';

// import PropTypes from 'prop-types';

// // material-ui
// import { Box, Card, Grid, Typography } from '@mui/material';

// // project imports
// import SubCard from 'ui-component/cards/SubCard';
// import MainCard from 'ui-component/cards/MainCard';
// import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
// import { gridSpacing } from 'store/constant';
// import { DataGrid } from '@mui/x-data-grid';
// import axios from 'axios';
// import { useContext, useEffect, useState } from 'react';
// import { countContext } from 'index';


// const columns = [
//     {
//         field: 'name',
//         headerName: 'Name',
//         width: 200
//     },
//     {
//         field: 'email',
//         headerName: 'Email',
//         width: 250
//     },
//     {
//         field: 'amount',
//         headerName: 'Amount',
//         width: 100
//     },
//     {
//         field: 'orderId',
//         headerName: 'Order ID',
//         type: 'number',
//         width: 250
//     },
//     {
//         field: 'paymentStatus',
//         headerName: 'Payment ID',
//         width: 100
//     }
// ];

// const ColorBox = ({ bgcolor, title, data, dark }) => (
//     <>
//         <Card sx={{ mb: 3 }}>
//             <Box
//                 sx={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     py: 4.5,
//                     bgcolor,
//                     color: dark ? 'grey.800' : '#ffffff'
//                 }}
//             >
//                 {title && (
//                     <Typography variant="subtitle1" color="inherit">
//                         {title}
//                     </Typography>
//                 )}
//                 {!title && <Box sx={{ p: 1.15 }} />}
//             </Box>
//         </Card>
//         {data && (
//             <Grid container justifyContent="space-between" alignItems="center">
//                 <Grid item>
//                     <Typography variant="subtitle2">{data.label}</Typography>
//                 </Grid>
//                 <Grid item>
//                     <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
//                         {data.color}
//                     </Typography>
//                 </Grid>
//             </Grid>
//         )}
//     </>
// );

// ColorBox.propTypes = {
//     bgcolor: PropTypes.string,
//     title: PropTypes.string,
//     data: PropTypes.object.isRequired,
//     dark: PropTypes.bool
// };


// export default function ReviewList() {
//     const {state} = useLocation();
//     console.log(state.data)

//     const [row, setRow]= useState([])

    
//   return (
//     <div>
//         {state.data.map((item,index)=>{
//             if(item.CourseId==state.CourseId){
//                 return <p>{item.Review}</p>
//             }
//         })}


// <Grid container spacing={gridSpacing}>
//             <Grid item xs={12}>
//                 <div style={{ height: 400, width: '100%' }}>
//                     <DataGrid rows={row} columns={columns} pageSize={5} rowsPerPageOptions={[5]} checkboxSelection getRowId={(item)=>{
//                         // console.log(item)
//                         return item.id + item.orderId
//                     }}/>
//                 </div>
//             </Grid>
//             </Grid>


//     </div>
//   )
// }
