import React,{useState} from "react";
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid,Paper,  TextField, Button, Typography,Link, IconButton, FormControl, InputLabel } from '@mui/material';
// import VectorNew from "../components/VectorNew.png";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';





const NewPassword=({email,otp,handleClose,handleClose1}) =>  {


    const [password,setPassword]=useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
   


    const paperStyle={padding :20,height:'80vh',width:"max-content", margin:"20px auto"}


    const navigate = useNavigate();
    const [code,setCode]=useState("");
    const [newpassword,setNewpassword]=useState("");
    
   
    
   
   console.log(code,newpassword)
   const onSubmit=event=>{
    event.preventDefault();
    if(code==otp){axios({
      url:'https://cms.keewesolutions.com/university/putuserdata',
      method:'POST',
      data:{
      email:email,
      Password:newpassword
      }
  })
  .then(
      (response) => {
          console.log(response.status)
      if(response.status==200){
        handleClose()
        handleClose1()
          navigate('/');
      }
      }
  )}
  else{ alert("Otp is Incorrect")}
    
    }
    return(
        <Paper elevation={10} style={paperStyle}>
            <form onSubmit={onSubmit}>
            <Grid align='center'>
               
                
                <div align="center">
                {/* <img src={VectorNew} alt="imaage" style={{width: "-webkit-fill-available"}}/> */}
                </div>
                <h2>New Password</h2>
                <h6>Set new password for your account</h6>
            </Grid>
            <TextField 
            value={code}
            onChange={event=>setCode(event.target.value)}
            variant="outlined" label='Verify OTP' fullWidth required/>
            <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
            fullWidth
            id="outlined-adornment-password"
            label="password"
            onChange={event=>setNewpassword(event.target.value)}
            type={showPassword ? 'text' : 'password'}
            value={newpassword}
            
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{setShowPassword(true)}}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            
          />
          </FormControl>
            <Button type='submit'  variant="contained" style={{backgroundColor:"blue", color:"white", height:"56px",fontSize:"20px", marginTop:"10px", marginBottom:"10px"}} fullWidth>CONFIRM</Button>
            </form>
        </Paper>
)
}

export default NewPassword
    