import './Certi.css';
import logo from './mrslogo.png';
import logo1 from './mrsptu.png'
import Pdf from "react-to-pdf";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import sign1 from './images/'
// import sign2 from './images/'
// import sign3 from './images/'
import sign2 from './Images/coe.jpg'
import sign3 from './Images/reg.jpg'
import QRCode from "react-qr-code";
import { Button } from '@mui/material';
import axios from 'axios';
const ref = React.createRef();

function Certi({state}) {
  // const {state} = useLocation();
  console.log(state)
  const [data, setdata]= useState([])
  const [duration, setduration]= useState([])

  
  useEffect(async()=>{
     await axios({
      url:"https://cms.keewesolutions.com/university/courseDuration",
      method:"post",
      data:{id:state.courseID}
     }).then((res)=>{
      setduration(res.data.Item.courseType)
      console.log(res.data.Item)
        setdata(res.data.Item.courseDetails)}
     )
  },[])
  
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [45, 60]
  };
  // useEffect(()=>{
  //   setCode(ref);
  // },[ref])
  return (
    <div style={{ backgroundColor: "white",marginTop:"5rem" }}>
      {/* <Pdf targetRef={ref} filename="code-example.pdf" options={options} scale='5' >
        {({ toPdf }) => <Button onClick={toPdf}>Generate Pdf</Button>}
      </Pdf> */}
      <div className="background" ref={ref}>
        <div className="container" style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <p style={{ fontFamily: "Algerian", fontSize: "1.5rem", fontWeight: 200, textAlign: 'left', wordSpacing: "1.5rem", lineHeight: "200%", marginTop: 0, paddingLeft: "1rem" }}>C.N.MRSPTU/ONLINE/......
          </p>
          
            
            <br />

            <text style={{ fontSize: "2.3rem", fontWeight: 500, wordSpacing: "1rem" }}>ਮਹਾਰਾਜਾ ਰਣਜੀਤ ਸਿੰਘ ਪੰਜਾਬ ਟੈਕਨੀਕਲ ਯੂਨੀਵਰਸਿਟੀ, ਬਠਿੰਡਾ</text>
            <br />
            <text style={{ fontFamily: "old english text mt", fontSize: "2.3rem", fontWeight: 200, lineHeight: "120%", wordSpacing: "1rem" }}>Maharaja Ranjit Singh Punjab Technical University Bathinda</text>
            <br />
            <br />
            <text
              style={{
                fontFamily: "Poppins",
                fontSize: "1.25rem",
                fontWeight: "200",
                justifyContent: "center",
                display: "flex",
                lineHeight: "120%"
              }}
            >
              (A State University Established By Govt. of Punjab vide Punjab Act
              No. 5 of 2015 and Approved Under Section 2(f) & 12 (B) of UGC)
            </text>
            <div className="image" style={{ marginTop: "15px" }}>
              <img src={logo1} alt='this is logo' style={{ height: "7.5rem", width: "7.5rem" }} />
            </div>
            <img src={logo1} className='backgroundImage' alt='this is logo' />
            <div className="textarea" style={{ marginTop: "15px" }}>
              <text style={{ fontFamily: "Algerian" }}>CERTIFICATE OF COMPLETION</text>
              <div className="writearea" >
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >THIS IS TO CERTIFY THAT <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{state.userName.toLowerCase().split(' ').map(function (word) {
                  return (word.charAt(0).toUpperCase() + word.slice(1));
                }).join(' ')}</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >HAS SUCCESSFULLY COMPLETED THE ONLINE CERTIFICATION </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >COURSE IN <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{state.courseName}</span> WITH GRADE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{state.Percentage >= 90 ? "A+" : state.Percentage >= 80 & state.Percentage < 90 ? "A" :
                  state.Percentage >= 70 & state.Percentage < 80 ? "B+" : state.Percentage >= 60 & state.Percentage < 70 ? "B" :
                   state.Percentage >= 50 & state.Percentage < 60 ? "C" : state.Percentage < 50 ? "F" : ""}</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >COURSE CODE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{data.number}</span> OF DURATION<span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{data.duration}{duration=="hourly"?" hour":" week"}</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >DATE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{state.Date}</span>. </text>
              </div>
              {/* <text style={{ fontFamily: "old english text mt" }}>Given under the seal of the university</text> */}
              <br />
              <br />
              <br />


              <div className="signature">
                <div style={{ display: "block", width:"352px", textAlign:"left" }}>
                  <div>
                    <img src={sign3} alt='sign3' />
                  </div>
                  <div>
                    <text style={{ fontFamily: "Algerian", paddingLeft: "10px" }}>REGISTRAR</text>
                  </div>
                </div>

                {/* <div style={{ 'flex': 0.65 }} /> */}
                <div style={{ 'flex': 1, alignSelf:"center" }} >
                <QRCode  value="Sample" size="100" />
                </div>
                <div style={{ display: "block" }}>
                  <div>
                    <img src={sign2} alt='sign3' />
                  </div>
                  <div>
                    <text style={{ fontFamily: "Algerian", paddingLeft: "10px" }}>CONTROLLER OF EXAMINATION</text>
                  </div>
                </div>
              </div>
              {/* <div className="signature">
                <div>

                  <text style={{ fontFamily: "Algerian", paddingLeft: "10px" }}>REGISTRAR</text>

                </div>
                <div style={{ 'flex': 0.65 }} />

                <div style={{ 'flex': 1 }} />

                <div>
                  <img src={sign3} alt='sign3' />

                  <text style={{ fontFamily: "Algerian", paddingRight: "10px" }}>CONTROLLER OF EXAMINATION</text>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certi;
